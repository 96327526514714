import apiClient from '@/client/api';
import { ActionTypes } from './action-types';
import { MutationTypes } from './mutation-types';
import { AuthUser, AvailableBrandsActionsTypes, AvailableBrandsServiceData, AvailableBrandsStateTypes, IRootState } from '@/store/interfaces';
import { ActionTree } from 'vuex';
// import data from './sample.json';

export const actions: ActionTree<AvailableBrandsStateTypes, IRootState> & AvailableBrandsActionsTypes = {
    /*
     * アクティブなブランド情報の取得
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async [ActionTypes.FETCH_AVAILABLE_BRANDS](
        { commit },
        payload: { auth: AuthUser; serviceCd: string; unisCustomerCode: string; serviceName: string }
    ) {
        try {
            let serviceName = payload.serviceName;

            if (!serviceName) {
                throw new Error('表示するサービス名が取得できませんでした。');
            }

            // 「USENPAY QR」はMEMBERSで一覧を表示しないため空でコミット
            if (serviceName === 'USENPAY_QR') {
                commit(MutationTypes.SET_AVAILABLE_BRANDS, { serviceName, paymentType: [] });
                return;
            }

            const response = await apiClient.get(`/v2/pay/card-brands`, {
                headers: {
                    Authorization: payload.auth.idToken,
                    'x-service-cd': payload.serviceCd,
                    'x-unis-cd': payload.unisCustomerCode,
                    'x-umid': payload.auth.umid,
                },
            });

            // 200以外のステータスの場合はエラー
            if (response.status !== 200) {
                throw new Error(`APIステータスエラー: ${response.status}`);
            }
            // console.log(response.data);

            // TODO: 仮jsonデータ
            // const response = {
            //     data: data,
            // };

            // サービス名でレスポンスから検索
            const responseData: AvailableBrandsServiceData[] = response.data.service;
            let serviceData = responseData.find((v) => v.name === serviceName);

            // USEN PAYとUSEN PAY+が同時契約の場合は片方しか取得できないため、その片方のデータを使用する
            if (!serviceData && serviceName === 'USENPAY_MULTI') {
                serviceName = 'USENPAY_PLUS';
                serviceData = responseData.find((v) => v.name === 'USENPAY_PLUS');
            }
            if (!serviceData && serviceName === 'USENPAY_PLUS') {
                serviceName = 'USENPAY_MULTI';
                serviceData = responseData.find((v) => v.name === 'USENPAY_MULTI');
            }

            // serviceDataが取得できた場合はstateにコミット
            if (serviceData) {
                commit(MutationTypes.SET_AVAILABLE_BRANDS, { serviceName, paymentType: serviceData?.payment_type });
                return;
            }

            // serviceDataが取得できなかった場合は空でコミット
            if (!serviceData) {
                commit(MutationTypes.SET_AVAILABLE_BRANDS, { serviceName, paymentType: [] });
                return;
            }

            throw new Error('正常に処理を完了できませんでした。');
        } catch (error) {
            console.error(error);
            commit(MutationTypes.SET_ERROR, { error: true });
        }
    },
};
