import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import {
    TopMutationsTypes,
    TopStateTypes,
    SubscribedService,
    UnsubscribedService,
    RecommendService,
    CartItem,
    CartPointData,
    Banner,
    Info,
    Weather,
    QaService,
    DashboardServiceData,
} from './../../interfaces';

export const mutations: MutationTree<TopStateTypes> & TopMutationsTypes = {
    [MutationTypes.SET_SUBSCRIBED_SERVICES](state: TopStateTypes, payload: SubscribedService[]) {
        state.subscribedServices = payload;
    },
    [MutationTypes.SET_QA_SERVICES](state: TopStateTypes, payload: QaService[]) {
        state.qaServices = payload;
    },
    [MutationTypes.SET_UNSUBSCRIBED_SERVICES](state: TopStateTypes, payload: UnsubscribedService[]) {
        state.unsubscribedServices = payload;
    },
    [MutationTypes.SET_RECOMMEND_SERVICES](state: TopStateTypes, payload: RecommendService[]) {
        state.recommendServices = payload;
    },
    [MutationTypes.SET_RECOMMEND_CART_ITEMS](state: TopStateTypes, payload: CartItem[]) {
        state.recommendCartItems = payload;
    },
    [MutationTypes.SET_CART_POINT](state: TopStateTypes, payload: CartPointData | undefined) {
        state.cartPoint = payload;
    },
    [MutationTypes.SET_SPECIAL_BANNERS](state: TopStateTypes, payload: Banner[]) {
        state.specialBanners = payload;
    },
    [MutationTypes.SET_PR_BANNERS](state: TopStateTypes, payload: Banner[]) {
        state.prBanners = payload;
    },
    [MutationTypes.SET_INFOS](state: TopStateTypes, payload: Info[]) {
        state.infos = payload;
    },
    [MutationTypes.SET_INFO_DETAIL](state: TopStateTypes, payload: Info | undefined) {
        state.infoDetail = payload;
    },
    [MutationTypes.SET_WEATHER](state: TopStateTypes, payload: Weather | undefined) {
        state.weather = payload;
    },
    [MutationTypes.SET_DASHBOARD_SERVICES](state: TopStateTypes, payload: DashboardServiceData[]) {
        const dashboardServiceCodes = payload.map((service) => service.serviceCd);
        state.dashboardServiceCodes = dashboardServiceCodes;
        state.dashboardServices = payload;
    },
};
