export enum ActionTypes {
    FETCH_SUBSCRIBED_SERVICES = 'FETCH_SUBSCRIBED_SERVICES',
    FETCH_DASHBOARD_SERVICES = 'FETCH_DASHBOARD_SERVICES',
    FETCH_QA_SERVICES = 'FETCH_QA_SERVICES',
    FETCH_UNSUBSCRIBED_SERVICES = 'FETCH_UNSUBSCRIBED_SERVICES',
    FETCH_RECOMMEND_SERVICES = 'FETCH_RECOMMEND_SERVICES',
    FETCH_RECOMMEND_CART_ITEMS = 'FETCH_RECOMMEND_CART_ITEMS',
    FETCH_CART_POINT = 'FETCH_CART_POINT',
    FETCH_SPECIAL_BANNERS = 'FETCH_SPECIAL_BANNERS',
    FETCH_PR_BANNERS = 'FETCH_PR_BANNERS',
    FETCH_INFOS = 'FETCH_INFOS',
    FETCH_INFO_DETAIL = 'FETCH_INFO_DETAIL',
    FETCH_WEATHER = 'FETCH_WEATHER',
}
