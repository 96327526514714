export enum ActionTypes {
    FETCH_BRANDS = 'FETCH_BRANDS',
    FETCH_AREA = 'FETCH_AREA',
    FETCH_BRANCHES = 'FETCH_BRANCHES',
    FETCH_SHARE_MEMBERS = 'FETCH_SHARE_MEMBERS',
    FETCH_SHARE_BRANCHES = 'FETCH_SHARE_BRANCHES',
    FETCH_SHARE_ACCOUNT = 'FETCH_SHARE_ACCOUNT',
    CREATE_SHARE_ACCOUNT = 'CREATE_SHARE_ACCOUNT',
    UPDATE_SHARE_ACCOUNT = 'UPDATE_SHARE_ACCOUNT',
    DELETE_SHARE_ACCOUNT = 'DELETE_SHARE_ACCOUNT',
    FETCH_MY_ACCOUNT = 'FETCH_MY_ACCOUNT',
    UPDATE_MY_ACCOUNT_MAIL = 'UPDATE_MY_ACCOUNT_MAIL',
    VERIFY_MAIL_COMPLETE = 'VERIFY_MAIL_COMPLETE',
    FETCH_U2_ACTIVE = 'FETCH_U2_ACTIVE',
}
