import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import VueObserveVisibility from 'vue-observe-visibility';
import 'intersection-observer';
import { createPinia } from 'pinia';

import Amplify from 'aws-amplify';
Amplify.configure({
    Auth: {
        identityPoolId: process.env.VUE_APP_AWS_AUTH_IDENTITY_ID,
        region: process.env.VUE_APP_AWS_AUTH_REGION,
        userPoolId: process.env.VUE_APP_AWS_AUTH_USER_POOL_ID,
        userPoolWebClientId: process.env.VUE_APP_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
        storage: window.sessionStorage,
    },
});

createApp(App).use(store).use(router).use(VueObserveVisibility).use(createPinia()).mount('#app');
