import { AvailableBrandsStateTypes } from './../../interfaces';

export const state: AvailableBrandsStateTypes = {
    serviceName: '',
    cardBrands: {
        abbreviation: '',
        status: '',
        brand: [],
    },
    eMoneyBrands: {
        abbreviation: '',
        status: '',
    },
    qrCodeBrands: {
        abbreviation: '',
        status: '',
        brand: [],
    },
    error: false,
};
