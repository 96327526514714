export enum MutationTypes {
    SET_SUBSCRIBED_SERVICES = 'SET_SUBSCRIBED_SERVICES',
    SET_QA_SERVICES = 'SET_QA_SERVICES',
    SET_UNSUBSCRIBED_SERVICES = 'SET_UNSUBSCRIBED_SERVICES',
    SET_RECOMMEND_SERVICES = 'SET_RECOMMEND_SERVICES',
    SET_RECOMMEND_CART_ITEMS = 'SET_RECOMMEND_CART_ITEMS',
    SET_CART_POINT = 'SET_CART_POINT',
    SET_SPECIAL_BANNERS = 'SET_SPECIAL_BANNERS',
    SET_PR_BANNERS = 'SET_PR_BANNERS',
    SET_INFOS = 'SET_INFOS',
    SET_INFO_DETAIL = 'SET_INFO_DETAIL',
    SET_WEATHER = 'SET_WEATHER',
    SET_DASHBOARD_SERVICES = 'SET_DASHBOARD_SERVICES',
}
