import { TopStateTypes } from './../../interfaces';

export const state: TopStateTypes = {
    subscribedServices: [],
    qaServices: [],
    unsubscribedServices: [],
    recommendServices: [],
    recommendCartItems: [],
    cartPoint: undefined,
    specialBanners: [],
    prBanners: [],
    infos: [],
    infoDetail: undefined,
    weather: undefined,
    upayAppData: undefined,
    dashboardServices: [],
    dashboardServiceCodes: [],
};
