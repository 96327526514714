import { AvailableBrandsStateTypes, IRootState, AvailableBrandsGettersTypes } from '@/store/interfaces';
import { GetterTree } from 'vuex';

export const getters: GetterTree<AvailableBrandsStateTypes, IRootState> & AvailableBrandsGettersTypes = {
    serviceName: (state: AvailableBrandsStateTypes) => {
        return state.serviceName;
    },
    cardBrands: (state: AvailableBrandsStateTypes) => {
        return state.cardBrands;
    },
    eMoneyBrands: (state: AvailableBrandsStateTypes) => {
        return state.eMoneyBrands;
    },
    qrCodeBrands: (state: AvailableBrandsStateTypes) => {
        return state.qrCodeBrands;
    },
    error: (state: AvailableBrandsStateTypes) => {
        return state.error;
    },
};
