import { createStore } from 'vuex';
import { IRootState } from '@/store/interfaces';
import { AuthStoreModuleTypes } from './modules/auth/types';
import { TopStoreModuleTypes } from './modules/top/types';
import { AccountStoreModuleTypes } from './modules/account/types';
import { InquiryStoreModuleTypes } from './modules/inquiry/types';
import { InvoiceStoreModuleTypes } from './modules/invoice/types';
import { ContractStoreModuleTypes } from './modules/contract/types';
import { UPayStoreModuleTypes } from './modules/upay/types';
import { PaymentStoreModuleTypes } from './modules/payment/types';
import { AvailableBrandsStoreModuleTypes } from './modules/available-brands/types';
import VuexPersist from 'vuex-persist';

import auth from './modules/auth';
import top from './modules/top';
import account from './modules/account';
import inquiry from './modules/inquiry';
import invoice from './modules/invoice';
import contract from './modules/contract';
import upay from './modules/upay';
import payment from './modules/payment';
import availableBrands from './modules/available-brands';

const vuexSessionStorage = new VuexPersist({
    key: 'membersStorage', // The key to store the state on in the storage provider.
    storage: window.sessionStorage, // or window.sessionStorage or localForage
    // Function that passes the state and returns the state with only the objects you want to store.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reducer: (state: any) => ({
        auth: state.auth,
    }),
});

export const store = createStore<IRootState>({
    state: {
        version: '1.0.0',
    },
    modules: {
        auth,
        top,
        account,
        inquiry,
        invoice,
        contract,
        upay,
        payment,
        availableBrands,
    },
    plugins: [vuexSessionStorage.plugin],
});

type StoreModules = {
    auth: AuthStoreModuleTypes;
    top: TopStoreModuleTypes;
    account: AccountStoreModuleTypes;
    inquiry: InquiryStoreModuleTypes;
    invoice: InvoiceStoreModuleTypes;
    contract: ContractStoreModuleTypes;
    upay: UPayStoreModuleTypes;
    payment: PaymentStoreModuleTypes;
    availableBrands: AvailableBrandsStoreModuleTypes;
};

export type Store = AuthStoreModuleTypes<Pick<StoreModules, 'auth'>> &
    TopStoreModuleTypes<Pick<StoreModules, 'top'>> &
    AccountStoreModuleTypes<Pick<StoreModules, 'account'>> &
    InvoiceStoreModuleTypes<Pick<StoreModules, 'invoice'>> &
    ContractStoreModuleTypes<Pick<StoreModules, 'contract'>> &
    InquiryStoreModuleTypes<Pick<StoreModules, 'inquiry'>> &
    UPayStoreModuleTypes<Pick<StoreModules, 'upay'>> &
    PaymentStoreModuleTypes<Pick<StoreModules, 'payment'>> &
    AvailableBrandsStoreModuleTypes<Pick<StoreModules, 'availableBrands'>>;
