import { MutationTree } from 'vuex';
import { MutationTypes } from './mutation-types';
import { AvailableBrandsMutationsTypes, AvailableBrandsStateTypes, AvailableBrandsListData } from '@/store/interfaces';

export const mutations: MutationTree<AvailableBrandsStateTypes> & AvailableBrandsMutationsTypes = {
    [MutationTypes.SET_AVAILABLE_BRANDS](state: AvailableBrandsStateTypes, payload: { serviceName: string; paymentType: AvailableBrandsListData[] }) {
        state.serviceName = payload.serviceName;
        payload.paymentType.forEach((v) => {
            if (v.abbreviation === 'CARD') {
                state.cardBrands = v;
            }
            if (v.abbreviation === 'EMONEY') {
                state.eMoneyBrands = v;
            }
            if (v.abbreviation === 'QRCODE') {
                state.qrCodeBrands = v;
            }
        });
    },
    [MutationTypes.SET_ERROR](state: AvailableBrandsStateTypes, payload: { error: boolean }) {
        state.error = payload.error;
    },
};
